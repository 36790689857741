import React from 'react'

import { Helmet } from 'react-helmet';

import snap1 from '../../assets/1.webp';
import snap2 from '../../assets/2.webp';
import snap3 from '../../assets/3.webp';
import snap4 from '../../assets/4.webp';
import snap5 from '../../assets/5.webp';
import snap6 from '../../assets/6.webp';
import snap7 from '../../assets/7.webp';
import snap8 from '../../assets/8.webp';

import amazonImage from "../../assets/amazon_wholesale.webp"
import arbitrageImage from "../../assets/online_arbritrage.webp"

import identify from '../../assets/brandhunting.webp';
import introduce from '../../assets/Bestselling_products_on_Amazon_Europe-removebg-preview.png';
import order from '../../assets/order and customer support.webp';
import account from '../../assets/accountmanagement.webp';

import image from "../../assets/service-bg.webp"

import ScheduleCall from '../../components/scheduleCall';

import Navbar from '../../components/Navbar';
import { NavMob } from '../../components/sidebar/NavMob';

import Process from '../../components/WorkProcess';
import CenterMode from '../../components/CasesSlider/Slider';

import CountUp from 'react-countup';

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from '../../screenSizes';
import Packages from '../../components/Packages';

function AmazonAutomation() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (

        <>

            <Helmet>
                <title>Services - EC Professional</title>
            </Helmet>

            <section className='section'>

                <div className='banner'>
                    {
                        isMobile ?
                            <>
                                <NavMob />
                                <br />
                                <br />
                                <br />
                                <br />

                            </>
                            :
                            <Navbar />
                    }
                    <div className="row m-0 mx-5 py-5 justify-content-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="content" style={{ paddingBottom: "5.5rem", marginLeft: "60px" }}>
                                <div className="para py-4">
                                    <h1>Amazon <br /> Wholesale FBA</h1>
                                    <p>We take your earning potential to new heights with our comprehensive Amazon Wholesale FBA strategy. We will design and execute a plan from the ground up, ensuring optimal conversions. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 image-col">
                            <div className="image" data-aos={isMobile ? "fade-right" : "fade-left"} style={{ alignItems: "center", display: "flex", marginRight: "80px" }}>
                                <img src={image} alt="image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="content">
                        <h1>AMZ AUTOMATION</h1>
                    </div> */}
                </div>
            </section>

            <div className="container service-contaier">

                {/* WORKS SECTION */}

                <Process />

                {/* <div className="row mt-5 m-0 justify-content-center">
                    <div className="col-lg-12">
                        <p className='para-1 color1'>We believe in being honest, transparent, goal oriented and
                            customer centric which are some of the beliefs that bring us
                            together as entrepreneurs to bring you the best AMZ FBA Automation.
                        </p>
                        <p className='para-2 color2'>
                            Create a residual income by investing in an FBA Automation store created, optimized, and managed by Next Gen. We can help set up an Automated AMZ Store with a winning strategy that guarantees your success. You’ll be able to make your money work hard for you and create a lifestyle others dream of. <br /> <br />
                            At Next Gen, we believe that success must be shared at all times and be a common goal therefore we will not surrender until you enjoy the results that we promise to deliver on each and every store! When there is a challenge, we bond even closer as every challenge enhances for the next time around.
                        </p>
                    </div>
                </div>

                <section className='section-two'>
                    <div className="row col mt-2 m-0 justify-content-center">
                        <div className="col-lg-3 col-md-3 col-sm-4">
                            <div className="coloumn">
                                <h3 className='color1'><CountUp className='number' end={120} suffix="+" /></h3>
                                <p className='color2'>Stores Being Managed</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-4">
                            <div className="coloumn">
                                <h3 className='color1'><CountUp className='number' end={110} suffix="+" /></h3>
                                <p className='color2'>Next Gen Team</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-4">
                            <div className="coloumn">
                                <h3 className='color1'><CountUp className='number' end={100} suffix="+" /></h3>
                                <p className='color2'>Customer Sales</p>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className='section-text'>
                    <div className="row headingRow mx-5 mt-5 justify-content-center text-center">
                        <div className="MainDiv position-relative">
                            <div className="HeadingDiv">
                                <h4>OFFERING YOU AN ALL-INCLUSIVE SOLUTION</h4>
                            </div>
                        </div>
                        <p style={{ fontSize: "14px" }}>Our services ensure your profits multiply as we keep working on your Amazon store.</p>
                    </div>

                    <div className="section-cards">
                        <div className="containerDiv" style={{ maxWidth: "1128px", margin: "auto" }}>
                            <div className="row mx-5 justify-content-center">
                                <div className="col-lg-6 col-md-6 col-sm-6 cards">
                                    <div className="amazonMain ">
                                        <div className="content amazonText">
                                            <h2>Amazon <br />
                                                <span>Wholesale</span>
                                            </h2>
                                            <p>Our amazon wholesale FBA initiates from in-depth market research and analysis followed by designing the strategies to find out the most demanding product for our client by making connections with manufacturers and wholesale vendors to ensure an undisturbed supply chain of the product. Finally, by listing the product on amazon, we maximize our client's sales and earnings. Because the more our clients earn, the more successful we are.</p>
                                        </div>
                                        {/* <div className="amazonImage">
                                            <img src={amazonImage} alt="" className='img-fluid' />
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6  cards">
                                    <div className="amazonMain h-100">
                                        <div className="content amazonText">
                                            <h2>Online <br />
                                                <span>Arbitrage</span>
                                            </h2>
                                            <p>Our strategy of Online Arbitrage services involves sourcing products from various online retailers and offering them for sale on Amazon at a higher price. The objective is to determine items available at a lower cost on other websites and introduce them on amazon for selling on a profit margin. </p>
                                        </div>
                                        {/* <div className="amazonImage">
                                            <img src={arbitrageImage} alt="" className='img-fluid' />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-services">
                        <div className="containerDiv" style={{ maxWidth: "1128px", margin: "auto" }}>
                            <div className="row mx-5 justify-content-center align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="image">
                                        <img src={identify} alt="" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="content text-center">
                                        <h3 className='color1'>Identifying The Product</h3>
                                        <p>Identifying the product involves collecting and analyzing target consumers' buying behaviours to cater to their needs. It helps to determine the optimal product and pricing strategy for your business. We prioritize finding a trustworthy supplier to meet our sourcing requirements throughout the process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-services">
                        <div className="containerDiv" style={{ maxWidth: "1128px", margin: "auto" }}>
                            <div className="row row2 mx-5 justify-content-center align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="content text-center">
                                        <h3 className='color1'>Introducing The Product On Amazon</h3>
                                        <p>Our specialists will conduct thorough research to choose the most suitable keywords, craft captivating product descriptions, and produce eye-catching images to elevate your listing. We will ensure that your listing reaches the appropriate target audience at an advantageous price, ensuring its position at the top for maximum sales conversions.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="image">
                                        <img src={introduce} alt="" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-services">
                        <div className="containerDiv" style={{ maxWidth: "1128px", margin: "auto" }}>
                            <div className="row mx-5 justify-content-center align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="image">
                                        <img src={order} alt="" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="content text-center">
                                        <h3 className='color1'>Order & Customer Support</h3>
                                        <p>Our services encompass every aspect of product procurement, inventory control, and documentation to guarantee seamless delivery to your customers. We guarantee proper storage and tracking of products. We are also responsible for addressing any customer service inquiries and handling returns relating to the products. Our objective is to streamline the process for you, enabling you to concentrate on expanding your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-services">
                        <div className="containerDiv" style={{ maxWidth: "1128px", margin: "auto" }}>
                            <div className="row row2 mx-5 justify-content-center align-items-center">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="content text-center">
                                        <h3 className='color1'>Account Management</h3>
                                        <p>Our services range from sourcing products and listing items to resolving setup-related challenges and managing your Amazon store. Our e-commerce experts are skilled in providing done-for-you service automation and managing your account hands-off, even after you have achieved high sales, substantial customer traffic, and multiple profit margins. </p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="image">
                                        <img src={account} alt="" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mt-4 mx-5">

                        <div className="content mb-5">
                            <h2 className='color1'>Amazon Wholesale</h2>
                            <p>Our amazon wholesale FBA initiates from in-depth market research and analysis followed by designing the strategies to find out the most demanding product for our client by making connections with manufacturers and wholesale vendors to ensure an undisturbed supply chain of the product. Finally, by listing the product on amazon, we maximize our client's sales and earnings. Because the more our clients earn, the more successful we are.</p>
                        </div>

                        <div className="content mb-5">
                            <h2 className='color1'>Online Arbitrage</h2>
                            <p>Our strategy of Online Arbitrage services involves sourcing products from various online retailers and offering them for sale on Amazon at a higher price. The objective is to determine items available at a lower cost on other websites and introduce them on amazon for selling on a profit margin. </p>
                        </div>

                        <div className="content mb-5">
                            <h2 className='color1'>IDENTIFYING THE PRODUCT</h2>
                            <p>Identifying the product involves collecting and analyzing target consumers' buying behaviours to cater to their needs. It helps to determine the optimal product and pricing strategy for your business. We prioritize finding a trustworthy supplier to meet our sourcing requirements throughout the process.</p>
                        </div>

                        <div className="content mb-5">
                            <h2 className='color1'>INTRODUCING THE PRODUCT ON AMAZON</h2>
                            <p>Our specialists will conduct thorough research to choose the most suitable keywords, craft captivating product descriptions, and produce eye-catching images to elevate your listing. We will ensure that your listing reaches the appropriate target audience at an advantageous price, ensuring its position at the top for maximum sales conversions.</p>
                        </div>

                        <div className="content mb-5">
                            <h2 className='color1'>ORDER & CUSTOMER SUPPORT</h2>
                            <p>Our services encompass every aspect of product procurement, inventory control, and documentation to guarantee seamless delivery to your customers. We guarantee proper storage and tracking of products. We are also responsible for addressing any customer service inquiries and handling returns relating to the products. Our objective is to streamline the process for you, enabling you to concentrate on expanding your business.</p>
                        </div>

                        <div className="content">
                            <h2 className='color1'>ACCOUNT MANAGEMENT</h2>
                            <p>Our services range from sourcing products and listing items to resolving setup-related challenges and managing your Amazon store. Our e-commerce experts are skilled in providing done-for-you service automation and managing your account hands-off, even after you have achieved high sales, substantial customer traffic, and multiple profit margins. </p>
                        </div>

                    </div> */}

                </section>

                {/* PACKAGES SECTION */}

                <Packages />

                <div className="container contTwo">
                    <section className='section-slider'>
                        <div className="slider mt-2 mx-5 m-0 justify-content-center">
                            <CenterMode />
                        </div>
                    </section>
                </div>

                {/* <section className='section-three'>
                    <div className="row col mt-2 mx-5 m-0 justify-content-center">

                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-right"}>
                                <img src={snap1} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-left"}>
                                <img src={snap2} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-right"}>
                                <img src={snap3} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-left"}>
                                <img src={snap4} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-right"}>
                                <img src={snap5} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-left"}>
                                <img src={snap6} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-right"}>
                                <img src={snap7} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-5 portfolioImg ">
                            <div className="snapshots" data-aos={isMobile ? "fade-up" : "fade-left"}>
                                <img src={snap8} alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

            <ScheduleCall />

        </>
    )
}

export default AmazonAutomation
