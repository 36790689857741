import ScheduleBtn from './button/ScheduleBtn';


function ScheduleCall() {

    return (
        <>
            <section className='section-call' id='contact-us'>
                <div className="scheduleCall mt-5">
                    <div className="text">
                        <h1>GET IN TOUCH WITH US TODAY FOR YOUR OWN HASSLE-FREE BUSINESS</h1>
                        {/* <p>Speak with a automation expert to help determine your investment goals!</p> */}
                        <div className='content d-flex justify-content-center'>
                            <ScheduleBtn styleClass="content-btn smallCenter" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default ScheduleCall;