import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from '../screenSizes';

function Packages() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (
        <>
            <div className="container contTwo">

                <section className='section-four'>
                    <div className="row firstRow mt-5 m-0 justify-content-center text-center">
                        <div className="col-lg-8">
                            <div className="package-content">
                                <h1 className='color1'>MULTIPLY YOUR INVESTMENT</h1>
                                <p>Partnering with ECP provides you with the opportunity to multiply your investment. Our packages are set in a way that it won't actually cost our clients anything compared to the benefits our clients get. Let us show you our well-designed packages</p>
                            </div>
                        </div>
                    </div>

                    <div className="row pkgRow mt-5 m-0 justify-content-center px-5">

                        <div className="col-lg-4 col-md-6 col-sm-6 pkgRowCol colms mb-5">
                            <div className="card" data-aos={isMobile ? "fade-right" : "fade-left"} >
                                <div className="card-header px-5 ">BASIC PACKAGE</div>
                                <div className="heading mt-4 mx-3">
                                    <h5 className='color1'>Investment</h5>
                                </div>
                                <ul>
                                    <li><p>Inventory buying budget : 50-100K USD </p></li>
                                </ul>
                                <div className="heading mx-3">
                                    <h5 className='color1'>Return on Investments and Value for money</h5>
                                </div>
                                <ul>
                                    <li><p>ROI for 1st year 40-60% , ROI for 2nd year  and onwards 70-80%</p></li>
                                    <li><p>Expected monthly profit from 6th month is up to 2000$/month</p></li>
                                    <li><p>Development of Amazon store as flip able asset of Valuation =Avg profit per month of an year X36 months)</p></li>
                                    <li><p>Business Relationship development with distributors and brands</p></li>
                                </ul>

                                <div className="heading mx-3">
                                    <h5 className='color1'>Mutual Consent</h5>
                                </div>
                                <ul>
                                    <li><p>One time service fee : 15% of Investment</p></li>
                                    <li><p>Profit sharing: 50-50</p></li>
                                </ul>

                                <div className="heading mx-3">
                                    <h5 className='color1'>What more you will get  </h5>
                                </div>
                                <ul>
                                    <li><p>Transparency of work </p></li>
                                    <li><p>Offering money back guarentee of service charges, if we fail to fulfill our obligations within 6 months.</p></li>
                                </ul>
                                <div className="button" style={{marginTop: "33px"}}>
                                    <a className='btn' href="https://calendly.com/wajheeabubakar/demo-event" target={'_blank'} >Get More Information</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 pkgRowCol colms mb-5">
                            <div className="card" data-aos={isMobile ? "fade-right" : "fade-down"} >
                                <div className="card-header ">STANDARD PACKAGE</div>
                                <div className="heading mt-4 mx-3">
                                    <h5 className='color1'>Investment</h5>
                                </div>
                                <ul>
                                    <li><p>Inventory purchasing budget : 100-300K USD</p></li>
                                </ul>
                                <div className="heading mx-3">
                                    <h5 className='color1'>Return on Investments and Value for money</h5>
                                </div>
                                <ul>
                                    <li><p>ROI for 1st year 40-60% , ROI for 2nd year  and onwards 70-80%</p></li>
                                    <li><p>Expected monthly profit from 6th month is up to 4000$/month  </p></li>
                                    <li><p>Development of Amazon store as flip able asset of Valuation =Avg profit per month of an year X36 months)</p></li>
                                    <li><p>Business Relationship development with distributors and brands</p></li>
                                </ul>
                                <div className="heading mx-3">
                                    <h5 className='color1'>Mutual Consent </h5>
                                </div>
                                <ul>
                                    <li><p>One time service fee : 12 % of investment  </p></li>
                                    <li><p>Profit sharing: 50-50</p></li>
                                </ul>
                                <div className="heading mx-3">
                                    <h5 className='color1'>What more you will get</h5>
                                </div>
                                <ul>
                                    <li><p>Trasnperncy of work  </p></li>
                                    <li><p>Offering money back guarentee of service charges, if we fail to fulfill our obligations within 6 months.</p></li>
                                    <li><p>Dedicated Key account representative </p></li>
                                </ul>
                                <div className="button">
                                    <a className='btn' href="https://calendly.com/wajheeabubakar/demo-event" target={'_blank'} >Get More Information</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 pkgRowCol colms mb-5">
                            <div className="card" data-aos="fade-right" >
                                <div className="card-header ">PREMIUM PACKAGE</div>
                                <div className="heading mt-4 mx-3">
                                    <h5 className='color1'>Investment</h5>
                                </div>
                                <ul>
                                    <li><p>Inventory buying budget : 300k- 1M USD</p></li>
                                </ul>
                                <div className="heading mx-3">
                                    <h5 className='color1'>Return on Investments and Value for money </h5>
                                </div>
                                <ul>
                                    <li><p>ROI for 1st year 40-60% , ROI for 2nd year  and onwards 70-80%</p></li>
                                    <li><p>Expected monthly profit from 6th month is up to 4000$/month </p></li>
                                    <li><p>Development of Amazon store as flip able asset of Valuation =Avg profit per month of an year X36 months)</p></li>
                                    <li><p>Business Relationship development with distributors and brands</p></li>
                                </ul>

                                <div className="heading mx-3">
                                    <h5 className='color1'>Mutual Consent</h5>
                                </div>
                                <ul>
                                    <li><p>One time service fee :10% of Investment  </p></li>
                                    <li><p>Profit sharing: 50-50</p></li>
                                </ul>

                                <div className="heading mx-3">
                                    <h5 className='color1'>What more you will get </h5>
                                </div>
                                <ul>
                                    <li><p>Transparency of work </p></li>
                                    <li><p>Offering money back guarentee of service charges, if we fail to fulfill our obligations within 6 months.</p></li>
                                    <li><p>Dedicated working team for your account/accounts</p></li>
                                </ul>
                                <div className="button">
                                    <a className='btn' href="https://calendly.com/wajheeabubakar/demo-event" target={'_blank'} >Get More Information</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Packages
