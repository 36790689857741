import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";


const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = () => (
  <>
    <motion.ul variants={variants} className="MobNavUl">
      {itemIds.map((item1, i) => (
        <MenuItem i={i} key={i} name={item1.name} link={item1.link} />
      ))}
    </motion.ul>
  </>
);

// const itemIds = [0, 1, 2, 3, 4];

const itemIds = [{
  name: "Home",
  link: "/"
},{
  name: "About",
  link: "/about-us"
},{
  name: "Services",
  link: "/services/amz-automation"
},{
  name: "How It Works",
  link: "/#how-it-works"
},{
  name: "Blog",
  link: "/blog"
},{
  name: "Contact Us",
  link: "/#contact-us"
}];