import React from 'react'

import { Link } from 'react-router-dom';


function ScheduleBtn({ styleClass }) {
    return (
        <div>
            <div className={`${styleClass || "schedule-btn"}`}>
                <a className="btn" href="https://calendly.com/contact-9306/ecprofessionals" target={'_blank'}>Schedule A Call</a>
                {/* <Link className="btn" to="/schedule-form">Schedule A Call</Link> */}
                {/* <a className="btn" href="https://calendly.com/wajheeabubakar/demo-event" target={'_blank'}>Schedule A Call</a> */}
                {/* <Link className="btn" to="https://calendly.com/uk1234930/demo-event" target={'_self'}>Schedule A Call</Link> */}
                {/* <button className="btn">Schedule A Call</button> */}
            </div>
        </div>
    )
}

export default ScheduleBtn
