import React from 'react'

import icon1 from "../assets/planning_3.webp";
import icon2 from "../assets/reserch_1.webp";
import icon3 from "../assets/optimization.webp";
import icon4 from "../assets/result.webp";

function Process() {
    return (
        <>
            <div className="container contTwo">

                <section className='section-three' id='how-it-works' >
                    <div className="row mt-5 m-0 justify-content-center">
                        <div className="col-lg-8">
                            <div className="work-content">
                                <h1 className='color1'>Our Process Flow For a Successful ROI</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 m-0 justify-content-center text-center">
                        <div className="col-lg-3 col-md-6 col-6 coloms mt-5">
                            <div className="work-card" data-aos="fade-up">
                                <div className="images">
                                    <img src={icon1} alt="icon1" className="img-fluid" />
                                    <h4>Planning</h4>
                                </div>
                                <div class="overlay">
                                    <div class="text">
                                        <p>Identifying our client's core objectives and goals enables us to implement result-oriented techniques in our eCommerce plan, ensuring our solutions will work exponentially for our clients..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 coloms mt-5">
                            <div className="work-card borders" data-aos="fade-down">
                                <div className="images">
                                    <img src={icon2} alt="icon1" className='img-fluid' />
                                    <h4>Research</h4>
                                </div>
                                <div className="overlay">
                                    <div className="text">
                                        <p>Our thorough research enables us to decide on the exact details of our strategy to accomplish our client's business objectives, keeping in consideration the market trends, products, target audience, and platforms.</p>
                                    </div>
                                </div>
                                {/* <img src={secondCircleImg} alt="" className='imageCircle' /> */}
                                {/* <div className="circleText"> */}
                                {/* <span className='span-two'>02</span> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 coloms mt-5">
                            <div className="work-card" data-aos="fade-up">
                                <div className="images">
                                    <img src={icon3} alt="icon1" className='img-fluid' />
                                    <h4>Optimization</h4>
                                </div>
                                <div className="overlay">
                                    <div className="text">
                                        <p>Optimizing our client’s products and listings strengthens our chances of fruitful conversions and enhances our reach in our client’s target market, maximizing sales.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6 coloms mt-5">
                            <div className="work-card borders" data-aos="fade-down">
                                <div className="images">
                                    <img src={icon4} alt="icon1" className='img-fluid' />
                                    <h4>Results</h4>
                                </div>
                                <div className="overlay">
                                    <div className="text">
                                        <p>Our top-of-the-line solutions, result-oriented strategies, and smartly designed eCommerce services work on a core goal of achieving maximum ROIs and delivering remarkable outcomes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Process
