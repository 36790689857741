import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/Navbar'
import { NavMob } from '../components/sidebar/NavMob'

import { MobileSize } from '../screenSizes'
import { useMediaQuery } from 'react-responsive'


function Blog() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (
        <>

            <Helmet>
                <title>Blog - EC Professional</title>
            </Helmet>

            <div className="container-fluid p-0">
                <section className='section-banner'>
                    {
                        isMobile ?
                            <>
                                <NavMob />
                                <br />
                                <br />
                                <br />
                                <br />

                            </>
                            :
                            <Navbar />
                    }
                    <div className='banner-2'>
                        <div className="content">
                            <h1 style={{ fontWeight: "bold" }}>Blog</h1>
                        </div>
                    </div>
                </section>
            </div>

            <section className='blog-section'>
                <div className="row justify-content-center text-center">
                    <h1>"Coming Soon"</h1>
                </div>
            </section>
        </>
    )
}

export default Blog
