import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import logo from "../../assets/EC-Professionals-LOGO.webp"

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

export const NavMob = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav initial={false} animate={isOpen ? "open" : "closed"} className={`${ isOpen === false && "testing"}`} style={{ position: isOpen ? "fixed" : "absolute" }} custom={height} ref={containerRef}>
      <a className="navbar-brand MobNavLogo" to="/">
        <img src={logo} alt="logo" className="img-fluid" />
      </a>
      <motion.div className="background glassCard" variants={sidebar} style={{ backgroundColor: `${isOpen===false && "rgba(0,0,0,0.3)"}`}} />
      {
        isOpen === true && <Navigation />
      }
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};
