import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'

import video from "../assets/130510-first-c2-maintenance.json"

function LottieFilesAnimation() {
    return (
        <>
            <div className='container'>
                <Player
                    src={video}
                    className="player"
                    loop
                    autoplay
                />
            </div>
        </>
    )
}

export default LottieFilesAnimation
