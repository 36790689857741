import React from 'react'
import "../TermsOfServicePage/TermsOfService.css"

import { Helmet } from 'react-helmet'

import Navbar from '../../components/Navbar'
import { NavMob } from '../../components/sidebar/NavMob'

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from "../../screenSizes";
import ScheduleCall from '../../components/scheduleCall'

import CasesSlider from '../../components/CasesSlider/Slider'

function TermsOfServices() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (
        <>

            <Helmet>
                <title>Terms And Conditions - EC Professional</title>
            </Helmet>

            <div className="container-fluid p-0">
                <section className='section-banner'>
                    {
                        isMobile ?
                            <>
                                <NavMob />
                                <br />
                                <br />
                                <br />
                                <br />

                            </>
                            :
                            <Navbar />
                    }
                    <div className='banner-2'>
                        <div className="content">
                            <h1 style={{ fontWeight: "bold" }}>Terms and Conditions</h1>
                        </div>
                    </div>
                </section>
            </div>

            <div className="container">
                <div className="section-terms mx-5">

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>TERMS OF USE</h2>
                            </div>
                            <p>The decision of visiting our website, landing pages and availing our service is exclusively your choice. Any argument about protection is dependent upon our Privacy Policy and our Conditions of Use. This incorporates constraints on harms, mediation of questions, and the material law of state. On the off chance that the customer has any issues in regards to the Privacy Policy, they should get in touch with us through our contact information given on the website.</p>
                            <p>We own the rights to change our policies at any time. Therefore, we suggest that our visitors and customers thoroughly read the policy before they enter the website or avail any of our services.</p>
                            <p>We collect personal information and data about where guests go on our site, what pages they visit, how much time they spent on the pages and more. This data enables us to see which of our pages or services are most visited and helps us improve our services and understand our customers. We own the complete rights of all materials, including pictures, representations, plans, symbols, photos, content and different materials that are a part of this Site.</p>
                            <p>The customer may download or duplicate the Contents and other downloadable materials showed on the Site for their personal use as it were. No right, title or enthusiasm for any downloaded materials or programming is moved to the customer because of any such downloading or duplicating. The customer may not replicate (with the exception of as noted above), distribute, transmit, convey, show, alter, make subordinates, sell or partake in any deal or misuse of the site, its substance, or any related programming.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Testimonials</h2>
                            </div>
                            <p>All remarks, feedback, recommendations, thoughts, and different entries revealed, submitted or offered to Our Company on or by this Site or generally unveiled, submitted or offered regarding your utilization of this Site (all things considered, the "Remarks") will remain the Company's property.</p>
                            <p>The Company will claim complete rights, titles and interests and will not be constrained at all in its utilization, business or something else, with reference to the Comments. The Company is and will be under no commitment:</p>
                            <ul>
                                <li>To keep up any Comments in certainty;</li>
                                <li>To pay clients for any Comments; or</li>
                                <li>To react to any client Comments.</li>
                            </ul>
                            <p>The organization can likewise utilize the tributes and logos of the customers on its other web and disconnected properties.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Electronic Communications</h2>
                            </div>
                            <p>Any time the customer visits our site or sends messages to us, the customer is communicating with our organization electronically. It's automatic for the customer to get our correspondences electronically. Our Company will speak with the customer by email or by posting on this site. Hence the customer concurs that all understandings, notification, divulgences and significant correspondence fulfill and satisfy every legitimate prerequisite and are proportionate to any lawful explanation recorded as a hard copy.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Copyright/Trademarks</h2>
                            </div>
                            <p>All the material that is incorporated on this website, for example, content, designs, logos, buttons and their symbols, pictures, downloads, information arrangements, and programming, is the property of this organization or its substance providers and ensured according to the copyright laws. All products utilized at this site is the property of this organization or its product providers and ensured according to global copyright laws.</p>
                            <p>The trademark names utilized inside our destinations are the property of their individual organization or its auxiliaries and can't be utilized regarding any item or administration that isn't a piece of that organization.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Copyright Complaints</h2>
                            </div>
                            <p>The Company and its partners respect the licensed property of others. On the off chance that anybody perusing the site accepts that their work has been replicated in a manner that establishes copyright encroachment, please follow the procedure to making your claim.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Refund Policy</h2>
                            </div>
                            <p>EC Professionals will provide full refund if the project has not been started for the next 20 days of purchase. However, this policy can be terminated at any point of time regardless of the scenario and this only implies if the request is made upon viewing the initial concepts only (less the 10% processing fee). Furthermore, any revisions requested after viewing the initial concepts will void this offer and would be considered as continuation of the project.</p>
                            <ul className='roman-list'>
                                <li>The refund policy will not be applicable on any discounted offer.</li>
                                <li>The refund policy will not be applicable if the customer has been unresponsive for more than 20 days.</li>
                                <li>The customer can always request to get his project held for a certain period of time, however, refund won’t be applicable on such situation.</li>
                                <li>The refund will be voided if the refund is requested, over situations not related to the company such as, the business shutting down or partner not agreeing on the design or any scenarios not related to the company.</li>
                                <li>Urgent design projects are non-refundable.</li>
                                <li>The company will not refund any client whose work was outsourced to EC Professionals by a third party.</li>
                                <li>If the refund has been processed, the customer or the entity will have zero authority to use any source of work design that has been shared with the client. If found, it will be a breach of contract and legal proceedings will be acted upon.</li>
                                <li>The client will not be refunded any money after the payment has been disputed. Any of such scenarios will lead to termination of the contract.</li>
                                <li>The client can always reach out to EC Professionals in case of any difference between the contract and the commitments made with the client.</li>
                                <li>In case of duplicate charges, the 100% refund will be implied on such scenarios.</li>
                            </ul>
                            <p>Note: The refund will take 10-15 days after the cancellation request. However, the client will not use any of the provided work shared with the client. In case of bundle purchases, the service in question will be refunded only.</p>
                            <p>You can use the following ways to make sure that you refund is made.</p>
                            <ul>
                                <li>Call us</li>
                                <li>Click Live chat to start chatting with our representative</li>
                                <li>Email us</li>
                            </ul>
                            <p>Once the refund is made, EC Professionals will be the rightful owner of your designs.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Delivery Policy</h2>
                            </div>
                            <p>We deliver all our services according to the date specified on the Order Confirmation email. You will receive an email from us regarding your delivery. Once the order is placed, you will receive an order number so that you can track the status of your order and ask for revisions. We also offer Rapid Delivery service to our clients that they can avail by paying extra. Please contact our customer support to know more about it.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Revision Policy</h2>
                            </div>
                            <p>Our promise of unlimited revisions is guaranteed. Revision turnaround time is 48 hours.</p>
                            <p>Bill of Rights If you choose to partake in any activity or purchase any of our services, you are among the various customers who have safely and securely made purchases from us. You can place your order via call, email, or live chat. You can unsubscribe from our emails any time you want. If we make any changes to our website, or our services, we will immediately notify you through email.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>INFORMATION SECURITY POLICY</h2>
                            </div>
                            <p>EC Professionals believes information management is an essential part of good IT governance. Information security is a key component of EC Professionals’s overall security management framework, including system level security policies and security guidance.</p>
                            <p>Information security enables us to provide the best possible services on the market. EC Professionals has defined information security policy documentation that is available to our customers upon request. Our core principles for information security management rely on international security policy definitions that are adapted as necessary to the local situation for the following areas:</p>
                            <ul className='content-list'>
                                <li>Risk management</li>
                                <li>Organizing information security</li>
                                <li>Human resource security</li>
                                <li>Communications and operations management</li>
                                <li>System development and maintenance</li>
                                <li>Information security incident management</li>
                                <li>Business continuity management</li>
                                <li>Compliance</li>
                            </ul>
                            <p>Our information security policy is also compliant with the laws and regulations of Pakistan.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>E-Mail Security</h2>
                            </div>
                            <p>EC Professionals treats that all e-mails received and sent must be kept confidential and can only be accessed by the persons indicated in the e-mails.</p>
                            <p>The e-mails services used by EC Professionals meet the data security requirements of the business.</p>
                            <p>EC Professionals connects to the electronical mailboxes with a secure and recognized SSL / TLS protocol that ensures reliable information encryption. Also, electronical mailboxes are protected against spam.</p>
                            <p>All outgoing and incoming e-mails are encrypted, so there is a small possibility that it could be taken over by third parties.</p>
                            <p>So, you can safely send e-mails to us and open e-mails where the sender is EC Professionals.</p>
                        </div>
                    </div>

                </div>


                {/* SERVICES SECTION */}

                <section className='section-slider'>
                    <div className="slider mt-2 mx-5 m-0 justify-content-center">
                        <CasesSlider />
                    </div>
                </section>

            </div>

            <ScheduleCall />
        </>
    )
}

export default TermsOfServices
