import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./Slider.css";

import snap1 from '../../assets/1.webp';
import snap2 from '../../assets/2.webp';
import snap3 from '../../assets/3.webp';
import snap4 from '../../assets/4.webp';
import snap5 from '../../assets/5.webp';
import snap6 from '../../assets/6.webp';
import snap7 from '../../assets/7.webp';
import snap8 from '../../assets/8.webp';

import laptopCover from "../../assets/laptop.webp"

export default class CenterMode extends Component {
    render() {
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "200px",
            slidesToShow: 1,
            arrows: false,
            // autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        centerPadding: "150px",
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        // centerMode: false,
                        centerPadding: "100px",
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        centerMode: false,
                        centerPadding: "100px",
                    }
                }
            ]

        };
        return (
            <>
                <div className="row mx-0 justify-content-center text-center">
                    <div className="content">
                        <h1 className="color1">THE E-COMMERCE PROFESSIONALS</h1>
                        <p>Where Our Client’s Meet Their Success. <br />
                            Our Success Stories Are Better Displayed Than Told. Let Us Show You How Our Services Works <br />
                            Like a Charm For Our Clients.
                        </p>
                    </div>
                </div>
                <div className="SLIDER-AREA" style={{ position: "relative" }}>
                    <div className="laptopCover">
                        <img src={laptopCover} alt="" className="img-fluid" />
                    </div>
                    <div>
                        <Slider {...settings}>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap1} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap2} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap3} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap4} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap5} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap6} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap7} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="px-3">
                                    <div className="snapshots21">
                                        <img src={snap8} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </>
        );
    }
}