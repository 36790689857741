import React from 'react'
import FooterHead from "../assets/footerHead.webp"
import logo from "../assets/EC Professionals Logo 2.webp"

function Footer() {
    return (
        <>
            <section className='footer'>
                {/* <div style={{ background: "transparent", marginTop: "50px" }}>
                    <img className='img-fluid footerHeadImg' src={FooterHead} alt="FooterHead" />
                </div> */}
                <footer>
                    <div className="row m-0 justify-content-center firstRow">
                        <div className="col-lg-4 logo">
                            <div className="footer-content">
                                <p>"At EC Professionals, Our mission is to give our clients the freedom to
                                    launch and grow their own successful Amazon businesses. We are dedicated
                                    to providing the resources, advice, and support they need to succeed."
                                </p>
                                <img src={logo} alt="logo" className='img-fluid' />
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6">
                            <h5>QUICK LINKS</h5>
                            <div className="footer-content">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/about-us">About Us</a></li>
                                    <li><a href="/services/amz-automation">Services</a></li>
                                    <li><a href="/#how-it-works">How It Works</a></li>
                                    {/* <li><a href="/#FAQ">FAQ</a></li> */}
                                </ul>

                                {/* <h5>LEGAL</h5>
                                <ul>
                                    <li><a href="/terms-of-services">Terms Of Service</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/refund-policy">Refund Policy</a></li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6">
                            <h5>LEGAL</h5>
                            <div className="footer-content">
                                <ul>
                                    <li><a href="/terms-of-services">Terms Of Service</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/refund-policy">Refund Policy</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <h1>
                                LET US BUILD <br />
                                <span style={{ color: "#00e95b" }}>
                                    YOU A MILLION <br /> DOLLAR
                                </span> <br />
                                BUSINESS.
                            </h1>
                            <div className="footer-content d-flex">
                                <div>
                                    <i className="fa-solid fa-location-dot"></i>
                                </div>
                                <div className='mx-3'>
                                    <p>Karachi, Sindh, Pakistan.</p>
                                </div>
                            </div>
                            <div className="footer-content mb-3  d-flex">
                                <div>
                                    <i className="fa-sharp fa-solid fa-envelope"></i>
                                </div>
                                <div className='mx-3'>
                                    <a href="mailto:ecprofessionals@gmail.com" target="_blank">contact@ecprofessionals.co</a>
                                </div>
                            </div>
                            <div className="footer-content d-flex mb-4">
                                <div>
                                    <i class="fa-brands fa-whatsapp"></i>
                                </div>
                                <div className='mx-3'>
                                    <a href="https://call.whatsapp.com/voice/ZbN9Z782OCaRAcOnXJspZD" target="_blank">+923312745981</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mx-0 justify-content-center">
                        <div className="social-icons d-flex">
                            <a href="https://www.linkedin.com/in/ec-professionals-b53234238/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
                        </div>
                        <div className="social-icons d-flex">
                            <a href="https://www.instagram.com/ecprofessionals/" target="_blank"><i className="fa-brands fa-instagram"></i></a>
                        </div>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/ecprofessionals" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                        </div>
                        <div className="social-icons d-flex">
                            <a href="https://twitter.com/ecprofessional3" target="_blank"><i className="fa-brands fa-twitter"></i></a>
                        </div>
                        <div className="social-icons">
                            <a href="https://www.youtube.com/channel/UC-TTDyOHhb-Phht-pJ7bqsg" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                        </div>
                    </div>

                    <div className="row mx-0 text-center pt-4">
                        <div className="copyRight">
                            <p> Copyright © 2023 <b>EC Professionals</b> | ⛏ Built by <a style={{ color: "white", fontWeight: 600, textDecoration: "none" }} href="https://www.dtecherz.com/" target={"_blank"}>dTecherz</a>  </p>
                        </div>
                    </div>

                </footer>
            </section>

        </>
    )
}

export default Footer
