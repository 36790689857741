function Chat() {

    // <!--Start of Tawk.to Script-->
    // <script type="text/javascript">
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/63c5207747425128790dd428/1gmt0dlmb';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
    {/* </script> */}
    // <!--End of Tawk.to Script-->


    return(
        <></>
    )
}

export default Chat;