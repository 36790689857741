import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import HomePage from './pages/homepage';
import AboutUs from './pages/AboutUsPage/AboutUs';
import ScheduleForm from './pages/ScheduleForm';
import AmazonAutomation from './pages/ServicesPage/AmazonAutomation'
import Blog from './pages/Blog';
import TermsOfServices from "./pages/TermsOfServicePage/TermsOfServices"
import PrivacyPolicy from "./pages/PrivacyPolicyPage/PrivacyPolicy"
import RefundPolicy from "./pages/RefundPolicyPage/RefundPolicy"
import PageNotFound from "./pages/PageNotFound"

import "./components/amazonCardImg/amazonCardImg.css";
import "./components/sidebar/navMobile.css";
import Chat from './components/LiveChat';


function App() {

  return (
    <>

      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/services/amz-automation" element={<AmazonAutomation />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/terms-of-services" element={<TermsOfServices />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/refund-policy" element={<RefundPolicy />} />
        <Route exact path="/schedule-form" element={<ScheduleForm />} />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>

      <Chat />

      <Footer />

    </>
  );
}

export default App;
