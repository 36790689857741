import React from 'react'

function ScheduleForm() {
    return (
        <div>
            <div className="container ">

                <div className='form-container glassCard' data-aos="zoom-in">
                    <div className="divOne d-flex w-100">
                        <div className='divOneHeading p-5'>
                            <h3 className='mb-3'>Consultation -Ecommerce Automation Services</h3>
                            <div className='divOneContent'>
                                <div className='mb-2 text-muted d-flex'>
                                    <div className="icon">
                                        <i class="fas fa-solid fa-clock"></i>
                                    </div>
                                    <span className='mx-2'>50 min</span>
                                </div>
                                <div className='mb-2 text-muted d-flex'>
                                    <div className="icon">
                                        <i class="fas fa-solid fa-video"></i>
                                    </div>
                                    <span className='mx-2'>Web conferencing details provided upon confirmation.</span>
                                </div>
                                <div className='mb-2 text-muted d-flex'>
                                    <div className="icon">
                                        <i class="fas fa-solid fa-calendar"></i>
                                    </div>
                                    <span className='mx-2'>9:00pm - 9:50pm, Thursday, December 29, 2022</span>
                                </div>
                                <div className='mb-2 text-muted d-flex'>
                                    <div className="icon">
                                        <i class="fa-sharp fa-solid fa-earth-americas"></i>
                                    </div>
                                    <span className='mx-2'>Pakistan, Maldives Time</span>
                                </div>
                            </div>
                        </div>

                        <div className="divTwo">
                            <div className="col-lg-8">
                                <form className='p-5 schedule_Form'>
                                    <h4 className='mb-4'>Enter Details</h4>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label for="name">First Name *</label>
                                            <input type="text" className="form-control" name="name" id="name" placeholder="" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label for="name">Last Name *</label>
                                            <input type="text" className="form-control" name="name" id="name" placeholder="" />
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label for="email">Email *</label>
                                        <input className="form-control" placeholder="" name="email" type="email" />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label for="number">Phone Number *</label>
                                        <input className="form-control" type="number" placeholder='' name="contact" id="" />
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>Do you currently own a business? *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                No
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>Have you ever owned or do you currently own an Amazon e-commerce Store? *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                I currently own an Amazon store
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                I've owned an Amazon store in the past
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                I've never owned an Amazon store
                                            </label>
                                        </div>
                                    </div>

                                    <div className="from-group mb-4">
                                        <label>How soon would you like to get your Amazon Store set up? *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                I'm ready now!
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                Within 30 days
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                Not sure
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>Which plan are you interested in? *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                $20K One time service fee (60/40 on net profit)
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $30K One time service fee (70/30 split on net profit)
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $55K One time service fee (2 -$30K stores discounted $5k w/ 70/30 split on net profit for both stores)
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>How much cash do you have readily available to invest in your Amazon Store?</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                Less than $30K
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $30K to $60K
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $60K+
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>How much credit and or liquid cash do you have readily available to support your business?  *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                $5,000 - $15,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $15,000 - $25,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $25,000 - $50,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $50,000 - $100,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $100,000+
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>What is your annual household income? *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label text-muted" for="flexRadioDefault1">
                                                $5,000 - $15,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $15,000 - $25,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $25,000 - $50,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $50,000 - $100,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $100,000 - $250,000
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                $250,000+
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>What do you expect this investment will do for you? *</label>
                                        <textarea className="form-control" name="subject" id="" cols="30" rows="3"></textarea>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label>You can cancel or reschedule your call but if you do please let us know. Otherwise, we will put you on our blacklist and you won’t be able to book again. We do this to protect the system from bots *</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                            <label className="form-check-label text-muted" for="flexRadioDefault2">
                                                Yes, I understand and I will let you know if I need to cancel or reschedule via email or social media DM's
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label for="number">Send text messages to</label>
                                        <input className="form-control" type="number" placeholder='' name="contact" id="" />
                                    </div>

                                    <div className="form-group mb-0 schedule-btn">
                                        <button className="btn btn-primary" style={{ border: "none" }}>Schedule Event</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>





            </div>
        </div>
    )
}

export default ScheduleForm
