import React from 'react'
import "./AboutUs.css"

import { Helmet } from 'react-helmet'

import Navbar from '../../components/Navbar'
import { NavMob } from '../../components/sidebar/NavMob'

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from "../../screenSizes";
import ScheduleCall from '../../components/scheduleCall'

import CasesSlider from '../../components/CasesSlider/Slider'

function AboutUs() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (
        <>

            <Helmet>
                <title>About Us - EC Professional</title>
            </Helmet>

            <div className="container-fluid p-0">
                <section className='section-banner'>
                    {
                        isMobile ?
                            <>
                                <NavMob />
                                <br />
                                <br />
                                <br />
                                <br />

                            </>
                            :
                            <Navbar />
                    }
                    <div className='banner-2'>
                        <div className="content">
                            <h1 style={{ fontWeight: "bold" }}>About Us</h1>
                        </div>
                    </div>
                </section>
            </div>


            <div className="container">
                <div className="section-about mx-5">

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 data-aos="fade-down">Leading the Way in Digital Business Transformation</h2>
                            </div>
                            <p data-aos="fade-down">With a strong business background and having years of e-commerce experience in our portfolio, the team ECP is successfully running projects. Our never ending dedication, result oriented strategies, and groundbreaking solutions keep us motivated to achieve our goals and present extraordinary outcomes to our clients. </p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 data-aos="fade-down">Our Core Values</h2>
                            </div>
                            <p data-aos="fade-down">We believe in transparency of the process to ensure our clients are on the same page.</p>
                            <p data-aos="fade-down">Truth is our Foundation. We don’t lie.</p>
                            <p data-aos="fade-down">We believe in integrity, loyalty, and hardworking.</p>
                            <p data-aos="fade-down">We stand by our commitments.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 data-aos="fade-down">Our Vision</h2>
                            </div>
                            <p data-aos="fade-down">Our vision is to provide ease for traders around the globe while expanding the boundaries via e-commerce.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 data-aos="fade-down">Our Mission</h2>
                            </div>
                            <p data-aos="fade-down">Currently we are on a mission to provide hassle free passive income solution through Amazon FBA to get thousand of people out of rat race and think ahead of kitchen money. </p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 data-aos="fade-down">Reasons for Our Success</h2>
                            </div>
                            <p data-aos="fade-down">Our reasons for success are the principles of trade which are derived from the principals of most successful traders of history;  AbdurRahman-Bin-Auf (R.A) and Uthman-Bin-Affan (R.A).</p>
                            <ul data-aos="fade-down">
                                <li>We do not buy or sell on credit.</li>
                                <li>We keep the inventory rolling on feasible margins.</li>
                                <li>We do not stock to raise the prices.</li>
                                <li>We buy on the most feasible price and give appealing feedback about the products.’</li>
                                <li>We do not hide the faults (if any) while selling the products.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 data-aos="fade-down">Our Golden Objectives</h2>
                            </div>
                            <p data-aos="fade-down">One of our major objective is to dedicate a major proportion of our earning to be consumed for providing shelter to the homeless, feed the hungry, and support Orphans and helpless Adults.</p>
                            <p data-aos="fade-down">Secondly, We are working to create an eco-system where one can flourish financially having ease of doing business.</p>
                        </div>
                    </div>

                </div>

                {/* SERVICES SECTION */}

                <section className='section-slider'>
                    <div className="slider mt-2 mx-5 m-0 justify-content-center">

                        <CasesSlider />

                    </div>
                </section>

            </div>

            <ScheduleCall />
        </>
    )
}

export default AboutUs
