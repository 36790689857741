import React from 'react'
import "./PrivacyPolicy.css"

import { Helmet } from 'react-helmet';

import Navbar from '../../components/Navbar';
import { NavMob } from '../../components/sidebar/NavMob';

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from "../../screenSizes";
import ScheduleCall from '../../components/scheduleCall';

import CasesSlider from '../../components/CasesSlider/Slider';

function PrivacyPolicy() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (
        <>

            <Helmet>
                <title>Privacy Policy - EC Professional</title>
            </Helmet>

            <div className="container-fluid p-0">
                <section className='section-banner'>
                    {
                        isMobile ?
                            <>
                                <NavMob />
                                <br />
                                <br />
                                <br />
                                <br />

                            </>
                            :
                            <Navbar />
                    }
                    <div className='banner-2'>
                        <div className="content">
                            <h1 style={{ fontWeight: "bold" }}>Privacy Policy</h1>
                        </div>
                    </div>
                </section>
            </div>


            <div className="container">
                <div className="section-policy mx-5">
                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2 className='h2'>General</h2>
                            </div>
                            <p>This Privacy Policy describes how “EC Professionals” (“Company” or “we” hereby) collects uses and shares information provided by you when you visit our website located at https://ecprofessionals.com or use any of our services.</p>
                            <p>This policy also informs you about your rights as visitors or viewers of our website with respect to your Personal data and how you can get your questions answered about your Personal data and our Privacy Policy.</p>
                            <p>We own the rights to change our Privacy Policy anytime in the future. If we change the Policy, we will update it in this section of our website along with the date of revision.</p>
                            <p>When you visit our site or any of our services, you become subject to our Privacy Policy. You agree to our collection and use of your Personal Data and any other information. We use the data collected from our website according to the laws and regulations of collecting, sharing, using and transmitting of your Personal and non-Personal Data.</p>
                            <p>Please read our Privacy Policy and our Cookie Policy thoroughly and if you have any questions pertaining to it, please feel free to contact us through the contact information given at the bottom of the page.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Whose Personal Data Do We Process?</h2>
                            </div>
                            <p>We can process your personal data if:</p>
                            <p>You are a visitor, client or vendor of EC Professionals. (or are interested in becoming one);</p>
                            <p>You use our products and/or our services;</p>
                            <p>You work for our client and/or vendor, or someone who uses services and/or products that have been developed by us;</p>
                            <p>You are a client (or work for a client), who we want to tell about and offer our services and/or products;</p>
                            <p>You are our employee, subcontractor, or are interested in working with us.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>What Kind Of Personal Information Do We Collect?</h2>
                            </div>
                            <p>We collect personal data and other information provided to us either by you or by a third party.</p>
                            <p>This information can include:</p>
                            <p>Your name;</p>
                            <p>Information about your employer, subdivision, and official duties;</p>
                            <p>our address, phone number, email address, or any other contact details;</p>
                            <p>Personal information given to us during phone conversations, email correspondence, the use of our website (information gathered when you register to receive newsletters; place an order; participate in a contest, advertising campaign, or survey; form submits when you are reporting a problem);</p>
                            <p>Information regarding any cooperation between EC Professionals and you or your employer (such as requesting specifications for products we’ve developed and/or services we have provided to you or your employer);</p>
                            <p>Other personal information includes;</p>
                            <ul>
                                <li>Technical information including the IP address which you use to connect to the Internet;</li>
                                <li>Your registration information</li>
                                <li>The type and version of your browser;</li>
                                <li>Your time-zone;</li>
                                <li>Versions and information about your browser plugins;</li>
                                <li>Your operating system and platform;</li>
                                <li>Information about your visit, including visited URLs;</li>
                                <li>Your browsing history across our website;</li>
                                <li>Information about the time and date when you visited our website;</li>
                                <li>Services and pages that you saw and searched for;</li>
                                <li>Time spent on each page;</li>
                                <li>Page response time;</li>
                                <li>Information on your activities on any page of our website (such as scrolling and mouse clicks or hovers);</li>
                                <li>Ways in which you landed on pages as well as the phone number or social buttons you used to get in touch with us.</li>
                            </ul>
                            <p>If you provide EC Professionals with data of/from third parties (such as their names, email addresses, phone numbers, etc.), you guarantee that you have the rights to do so. In certain cases, EC Professionals and partner third-parties can automatically collect data using browser cookies, web logs, web beacons, and other similar applications and attachments.</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>What We Do With Your Personal Information</h2>
                            </div>
                            <p>We process your personal information for the following reasons:</p>
                            <p>Advertising and sales activities as well as presenting information about our services and products (in ways that we determine ourselves);</p>
                            <p>Creating and executing contracts and agreements with you or your employer to:</p>
                            <p>Provide services to clients and other users of these services;</p>
                            <p>Receive goods and services from vendors;</p>
                            <p>Internal processing, including fixes of defects, data analysis, testing, and collecting data for surveys;</p>
                            <p>Improving our website to provide content in the most effective way for you and the device you are using;</p>
                            <p>Improving safety during your visit of our website and use of our products and services;</p>
                            <p>Evaluation and understanding of the efficiency of the advertising that we display to you and other parties as well as displaying relevant advertising;</p>
                            <p>Providing tips and recommendations on services which could be interesting to you or your employer in accordance with your preferences (where applicable).</p>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>To Whom Do We Disclose Your Personal Information</h2>
                            </div>
                            <p>We can share your personal data with:</p>
                            <p>Any member of our company, including subsidiaries, affiliated companies.</p>
                            <p>Third parties, including:</p>
                            <p>Our business partners, clients, vendors, and subcontractors;</p>
                            <p>Our auditors, legal consultants, and other professional consultants or</p>
                            <p>We can share data collected via our website with:</p>
                            <p>Our advertisers and advertising networks which need this data to select and display relevant ads to you and others. We do not disclose any data that can personally identify you to advertisers, but we do provide general data about our users. We can also use this general data to help advertisers display ads to our target audience. We can use your personal data to demonstrate ads of our advertisers and their target audience.</p>
                            <p>To protect the rights, property, and security of EC Professionals, our clients, our employees, and other users. This includes sharing information with other companies and organizations (including law enforcement) with the goal of preventing fraud and other risks.</p>
                        </div>
                    </div>
                    
                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Usage Of Outbound Links</h2>
                            </div>
                            <p>EC Professionals websites might include links that lead to other websites, websites that are controlled by parties we are not related or connected to. After clicking an outbound link we lose any control of collection, storage, or processing of your personal data that is transferred</p>
                        </div>
                    </div>
                </div>


                {/* SERVICES SECTION */}

                <section className='section-slider'>
                    <div className="slider mt-2 mx-5 m-0 justify-content-center">

                        <CasesSlider />

                    </div>
                </section>

            </div>

            <ScheduleCall />

        </>
    )
}

export default PrivacyPolicy
