import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { MobileSize } from '../screenSizes';

import { Helmet } from 'react-helmet';

import laptop from "../assets/laptop.webp"
import VIDEO from "../assets/ECP Promo 01.mp4"
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

import Navbar from '../components/Navbar';
import { NavMob } from '../components/sidebar/NavMob';
import ScheduleBtn from "../components/button/ScheduleBtn";
import LottieFilesAnimation from '../components/LottieFilesAnimation';
import ScheduleCall from '../components/scheduleCall';
import AmazonImg from "../components/amazonCardImg/index"
import Process from '../components/WorkProcess';
import Packages from '../components/Packages';
import CenterMode from "../components/CasesSlider/Slider"



function HomePage() {

    const isMobile = useMediaQuery({ query: MobileSize })
    const isMobileSmall = useMediaQuery({ query: '(max-width: 425px)' })

    return (
        <>

            <Helmet>
                <title>EC Professional</title>
            </Helmet>

            <section className='qw8y87 mx-0 px-0'>
                <div className="container-fluid p-0">
                    <section className='section-one w-100'>
                        {
                            isMobile ?
                                <>
                                    <NavMob />
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                </>
                                :
                                <Navbar styleclass="homeNav" />
                        }
                        <div className="row m-0 mx-5 py-5 justify-content-center">
                            <div className="col-lg-5 col-md-6">
                                <div className="content" style={{ paddingBottom: "6.2rem", paddingTop: "4.5rem", marginLeft: "60px" }}>
                                    <div className="para">
                                        <h1>Maximizing your Amazon potential with expert support and solutions</h1>
                                        <p>We offer complete and hassle-free e-commerce solutions by handling all the hard work while our clients make a fortune. Our distinctive and diversified pool of services covers all your business concerns.</p>
                                    </div>
                                    <ScheduleBtn styleClass="bannerBtn" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 image-col">
                                <div className="image" data-aos={isMobile ? "fade-right" : "fade-left"} style={{ alignItems: "center", display: "flex", marginRight: "80px" }}>
                                    <LottieFilesAnimation />
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

                {/* WORKS SECTION */}
                <Process />

                {/* ABOUT SECTION */}

                <div className="container contTwo">
                    <section className='section-two' id='about'>
                        <div className="row mt-5 m-0 justify-content-center ">
                            <div className="col-lg-6">
                                <div className="aboutImg" data-aos={isMobile ? "fade-right" : "zoom-out"}>
                                    <h2>Invest In The <br /> Future Of <span>Businesses.</span></h2>
                                    <ScheduleBtn styleClass="aboutBtn" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className='aboutText'>
                                    E-commerce is the future of businesses; as the world is evolving and leaving
                                    traditional practices behind, be the first among your peers to grasp the
                                    opportunity of investing sensibly in going digital with your business.
                                    We offer you a one-stop solution by covering all of your concerns about your
                                    e-commerce store. As a premium e-commerce service provider partnering with
                                    ECP team enables you to take your business to new heights of digital success.
                                    We cover multiple e-commerce automation services platforms, among which
                                    Amazon Wholesale FBA is our speciality. Having the core objective of maximizing
                                    our client’s return on investment by ten-fold, we have successfully made our
                                    services renowned globally.
                                </p>
                                <div className='amazonLogo mx-auto mt-5'>
                                    {/* <img src={amazonLogo} alt="" className='img-fluid' /> */}
                                    <div className="flip-container" data-aos="flip-right">
                                        <div className="flipper">
                                            <div clas="front">
                                                <AmazonImg />
                                            </div>
                                            <div className="back">
                                                <AmazonImg />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <br />
                <br />
                <br />
                <br />
                <br />

                {/* VIDEO SECTION */}
                <section className='videoSection'>
                    <img src={laptop} alt="laptop" />
                    {/* <iframe
                        src={`${VIDEO}?autoplay=0`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    >
                    </iframe> */}
                    <Player>
                        <source src={VIDEO} />
                    </Player>
                </section>


                {/* SECTION ASPECT */}
                <div className="container contTwo">
                    <section className='section-aspect'>
                        <div className="row mx-5 justify-content-center text-center">
                            <div className="content">
                                <h1 className='color1'>Providing You a Stress Free Business Model</h1>
                                <p>
                                    We at ECP provide services from the first step until you make millions with your Amazon store. One of our core objectives is to cover all your business aspects so you can utilize your precious time for more important things and remain stress-free from your business operations. Following the process, we guide your business toward ultimate success by advising on operational business aspects, market trends, and technology. To make the process hassle-free, we manage your entire customer experience with your enterprise. Adapting to your business requirements enables us to boost your operational efficiency and productivity. Our expertise in Amazon automation strengthens us to monitor all your business aspects, including the performance, profitability, and efficiency of your Amazon whole FBA. Partnering with ECP gives you the opportunity to make extreme profits with ultimate peace of mind.
                                </p>
                            </div>
                        </div>
                        <div className="row m-0 mx-5 justify-content-center text-center">
                            <div className={`col-lg-4 col-md-6 ${isMobileSmall ? "col-12" : "col-6"} mt-5`}>
                                <div className="card">
                                    <h4 className="myH4">Product Research & Listings</h4>
                                    <span>01</span>
                                </div>
                            </div>
                            <div className={`col-lg-4 col-md-6 ${isMobileSmall ? "col-12" : "col-6"} mt-5`}>
                                <div className="card">
                                    <h4 className="myH4">Inventory Management</h4>
                                    <span>02</span>
                                </div>
                            </div>
                            <div className={`col-lg-4 col-md-6 ${isMobileSmall ? "col-12" : "col-6"} mt-5`}>
                                <div className="card">
                                    <h4 className="myH4">Order Processing & Returns</h4>
                                    <span>03</span>
                                </div>
                            </div>
                            <div className={`col-lg-4 col-md-6 ${isMobileSmall ? "col-12" : "col-6"} mt-5`}>
                                <div className="card">
                                    <h4 className="myH4">Order Tracking</h4>
                                    <span>04</span>
                                </div>
                            </div>
                            <div className={`col-lg-4 col-md-6 ${isMobileSmall ? "col-12" : "col-6"} mt-5`}>
                                <div className="card">
                                    <h4 className="myH4">Customer Service & Reviews</h4>
                                    <span>05</span>
                                </div>
                            </div>
                            <div className={`col-lg-4 col-md-6 ${isMobileSmall ? "col-12" : "col-6"} mt-5`}>
                                <div className="card">
                                    <h4 className="myH4">Grow & Scale Your Amazon Store</h4>
                                    <span>06</span>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                {/* INVEST SECTION */}
                <div className="container contTwo">
                    <section className='section-invest'>
                        <div className="row m-0 justify-content-center text-center">
                            <div className="content">
                                <h1 className='color1'>WHY YOU SHOULD INVEST WITH THE ECP</h1>
                            </div>
                        </div>
                        <div className="row mx-5">
                            <p>The E-Commerce Professionals takes on its projects very professionally.
                                Choosing to work with us has a number of unmatched advantages;
                            </p>
                            <ul>
                                <li>We keep our clients in a timely and transparent communication loop, this allows us to maintain trust with our clients through out the whole process. Moreover, they stay up-to-date with the progress.</li>
                                <li>Each of our client’s projects is handled by dedicated professional and experienced personnel from our ECP team.</li>
                                <li>We believe in not just taking on the clients but putting in all of our efforts to convert each project into a renowned success that speaks for itself.</li>
                                <li>We are suitable to perform under challenging conditions and still managed not to divert from the path to success.</li>
                                <li>We value our client’s suggestions and always manage to strike a balance between our expert services and our client’s vision for the project.</li>
                            </ul>
                        </div>
                    </section>
                </div>

                {/* PACKAGES SECTION */}
                <Packages />

                {/* SLIDER SECTION */}
                <div className="container contTwo">
                    <section className='section-slider'>
                        <div className="slider mt-2 mx-5 m-0 justify-content-center">
                            <CenterMode />
                        </div>
                    </section>
                </div>

                {/* <section className='section-faq' id='FAQ' data-aos="zoom-in">
                    <div className="row justify-content-center">
                        <div className="col-lg-11">
                            <div className="FAQ-content">
                                <h1 className='color1'>Frequently Asked Questions About Amazon Automation</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 m-0 justify-content-center">
                        <div className="col-lg-11 my-2">
                            <div className="questions">
                                <p className="btn btn-primary color1" style={{ backgroundColor: "transparent", border: "none", color: "black", fontSize: "18px", margin: 0 }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample">
                                    What is an Amazon automation?
                                </p>
                                <div className="collapse" id="collapseExample1">
                                    <div className="card card-body cardCustom color2">
                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-11 my-2">
                            <div className="questions">
                                <p className="btn btn-primary color1" style={{ backgroundColor: "transparent", border: "none", color: "black", fontSize: "18px", margin: 0 }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
                                    What does Amazon automation mean?
                                </p>
                                <div className="collapse" id="collapseExample2">
                                    <div className="card card-body cardCustom color2">
                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-11 my-2">
                            <div className="questions">
                                <p className="btn btn-primary color1" style={{ backgroundColor: "transparent", border: "none", color: "black", fontSize: "18px", margin: 0 }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample">
                                    How to start with Amazon automation?
                                </p>
                                <div className="collapse" id="collapseExample3">
                                    <div className="card card-body cardCustom color2">
                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-11 my-2">
                            <div className="questions">
                                <p className="btn btn-primary color1" style={{ backgroundColor: "transparent", border: "none", color: "black", fontSize: "18px", margin: 0 }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample">
                                    How much does Amazon automation cost?
                                </p>
                                <div className="collapse" id="collapseExample4">
                                    <div className="card card-body cardCustom color2">
                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section> */}

                <ScheduleCall />

            </section>

        </>
    )
}

export default HomePage
