import React from 'react';
import logo from "../assets/EC Professionals Logo 2.webp";
import { Link } from 'react-router-dom';


function Navbar({ styleClass }) {

    return (
        <>
            <nav className={`${styleClass || "navbar"} navbar-expand-lg navbar-light Main-Nav`} >
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="logo" className="img-fluid" />
                    </Link>
                    <button className="navbar-toggler text-light bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center px-5" id="navbarSupportedContent">
                        <ul className="navbar-nav nav-link mx-auto">
                            <li className="nav-item">
                                <Link className="nav-link active text-light" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active text-light" aria-current="page" to="/about-us">About Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active text-light" aria-current="page" to="/services/amz-automation">Services</Link>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle text-light" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <Link className="dropdown-item" to="/services/amz-automation">Amazon Automation</Link>
                                    </li>
                                </ul>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link active text-light" aria-current="page" href="/#how-it-works">How It Works</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active text-light" aria-current="page" href="/blog">Blog</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link active text-light" aria-current="page" href="/#FAQ">FAQ</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link active text-light" aria-current="page" href="/#contact-us">Contact Us</a>
                            </li>
                        </ul>

                        <div className='phone-call'>
                            <a className="nav-link contactNumber" href="https://wa.link/1z3txy" target={"_blank"}><span><i class="fa-brands fa-whatsapp mx-2"></i></span>+923312745981</a>
                        </div>
                        {/* <ScheduleBtn /> */}
                        {/* <div className="schedule-btn w-auto ">
                            <button className="btn">Schedule A Call</button>
                        </div> */}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
