import React from 'react'
import "../RefundPolicyPage/RefundPolicy.css"

import { Helmet } from 'react-helmet'

import Navbar from '../../components/Navbar'
import { NavMob } from '../../components/sidebar/NavMob'

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from "../../screenSizes";
import ScheduleCall from '../../components/scheduleCall'

import CasesSlider from '../../components/CasesSlider/Slider'

function RefundPolicy() {

    const isMobile = useMediaQuery({ query: MobileSize })

    return (
        <>

            <Helmet>
                <title>Refund Policy - EC Professional</title>
            </Helmet>

            <div className="container-fluid p-0">
                <section className='section-banner'>
                    {
                        isMobile ?
                            <>
                                <NavMob />
                                <br />
                                <br />
                                <br />
                                <br />

                            </>
                            :
                            <Navbar />
                    }
                    <div className='banner-2'>
                        <div className="content">
                            <h1 style={{ fontWeight: "bold" }}>Refund Policy</h1>
                        </div>
                    </div>
                </section>
            </div>


            <div className="container">
                <div className="section-refund mx-5">

                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Refund Policy Will Be Voided If,</h2>
                            </div>
                            <ul>
                                <li>A discounted package has been chosen.</li>
                                <li>The primary design concept has been approved.</li>
                                <li>You have demanded revisions.</li>
                                <li>Urgent design projects are non-refundable.</li>
                                <li>The cancellation has been made due to reasons non-related to the company.</li>
                                <li>The client is unresponsive or out of contact for more than 2 weeks of project.</li>
                                <li>Company’s policies, or policy, have been violated.</li>
                                <li>Other company or designer has been approached for the same project.</li>
                                <li>The creative brief is lacking in required information.</li>
                                <li>A complete design change has been demanded.</li>
                                <li>The claim has crossed the given ‘request for refund’ time span.</li>
                                <li>The business is closing or changing name or business.</li>
                                <li>Reasons such as ‘change of mind’, ‘disagreement with partner’ or other reasons that do not pertain to the service will not be subject to refund under any circumstances.</li>
                                <li>12% processing fee will be applicable to all the refunds requested from client side.</li>
                                <li>No refund can be claimed/processed once after the final files have been delivered to you following your request.</li>
                                <li>Refund and revision explicitly does not apply on items offered free.</li>
                                <li>Services including but not limited to Social Media, SEO, Domain Registration and Web Hosting are not entitled to refund under any circumstances.</li>
                                <li>In case of websites, refunds will not be entertained once the client has approved the design and the website is sent for development.</li>
                                <li>In case of logo, refunds will not be entertained once client has shared positive feedback on revisions and design is sent for final files.</li>
                                <li>Refund will not be applicable on the work outsourced by third party.</li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="section">
                        <div className="row">
                            <div className='heading'>
                                <h2>Amazon Refund Policy:</h2>
                            </div>
                            <ul>
                                <li>Client is eligible to submit refund request in case of service delivery failure from Service Provider if all the Pre-requisites were provided by the client in due time.</li>
                                <li>Refund is not applicable if Client fails to fulfill what is required (Business Information, Personal Information, Store Information and Essential Required Data and Documents) after the contract has been made and work has been started.</li>
                                <li>Service Provider is not responsible for any mishap occurs due to Client’s bad history of business on amazon, leading to Account Suspension.</li>
                                <li>Refund request will be processed within 30 working days when it is requested. (If applicable)</li>
                            </ul>
                        </div>
                    </div>

                </div>


                {/* SERVICES SECTION */}

                <section className='section-slider'>
                    <div className="slider mt-2 mx-5 m-0 justify-content-center">

                        <CasesSlider />

                    </div>
                </section>

            </div>

            <ScheduleCall />
        </>
    )
}

export default RefundPolicy
