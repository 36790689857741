import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { NavMob } from '../components/sidebar/NavMob';
import ScheduleBtn from '../components/button/ScheduleBtn';

import { useMediaQuery } from 'react-responsive'
import { MobileSize } from "../screenSizes";

export default function PNF() {
    const isMobile = useMediaQuery({ query: MobileSize })


    return <div>

        <Helmet>
            <title>Page Not Found - EC Professional</title>
        </Helmet>


        <div className="container-fluid p-0">
            <section className='section-banner'>
                {
                    isMobile ?
                        <>
                            <NavMob />
                        </>
                        :
                        <Navbar />
                }
                <div className='banner-2'>
                    <div className="content" style={{ flexDirection: "column", padding: "0px 20px"}}>
                        <h1 style={{ fontWeight: "bold", fontSize: "75px" }} className="text-center"> Page Not Found </h1>
                        <br />
                        <p style={{ maxWidth: "600px", color: "#fff", fontSize: "25px", textAlign: "center" }}>Sorry, the page you were looking for at this URL was not found.</p>
                    </div>
                </div>
            </section>
        </div>

        {/* ScheduleCall  */}
        <section className='section-call' id='contact-us' style={{ marginTop: "0px" }}>
            <div className="scheduleCall">
                <div className="text">
                    <h1>GET IN TOUCH WITH US TODAY FOR YOUR OWN HASSLE-FREE BUSINESS</h1>
                    {/* <p>Speak with a automation expert to help determine your investment goals!</p> */}
                    <div className='content d-flex justify-content-center'>
                        <ScheduleBtn styleClass="content-btn smallCenter" />
                    </div>
                </div>
            </div>
        </section>


    </div>
}